import { useEffect, useState } from 'react';
import axios from 'axios';
import { AuthInfo, AuthorizationGrant, Coin, Coins, ExtensionOptions, Fee, GenericAuthorization, LCDClient, MsgExecuteContract, MsgGrantAuthorization, MsgRevokeAuthorization, PublicKey, SendAuthorization, SignerInfo, StakeAuthorization, StakeAuthorizationValidators, Tx, TxBody, TxInfo, Validator, Wallet } from '@terra-money/terra.js';
import { ConnectedWallet, useConnectedWallet } from '@terra-money/wallet-provider';
import { Pagination } from '@terra-money/terra.js/dist/client/lcd/APIRequester';
import { Box, Typography } from '@material-ui/core';
import { Stack } from '@mui/material';
import { connect } from 'http2';

type GrantProp = {
  walletAddress: string,
  lcd: LCDClient | undefined,
};

 
async function getTaxAmount(amount?: Coins, lcd?: LCDClient) {
  if(!amount || !lcd) {
    return 0;
  }

  const taxRate = await(
    await fetch(lcd.config.URL + '/terra/treasury/v1beta1/tax_rate', {
      redirect: 'follow'
    })
  ).json();

  const amountUluna = amount.get('uluna');

  if(!amountUluna) {
    return 0;
  }

  const taxInUluna = amountUluna.amount.toNumber() * taxRate.tax_rate;

  return taxInUluna;
};

function isConnectedWallet(x: Wallet | ConnectedWallet): x is ConnectedWallet {
  if(!x) {
    return false;
  }
  return typeof (x as Wallet).key === "undefined";
};

async function waitForInclusionInBlock(lcd: LCDClient, txHash: string): Promise<TxInfo | undefined> {
  let res;
  for (let i = 0; i <= 50; i++) {
    try {
      res = await lcd.tx.txInfo(txHash);
    } catch (error) {
      // NOOP
    }
      
    if (res) {
      break;
    }
      
    await new Promise((resolve) => setTimeout(resolve, 500));
  }
      
  return res;
};

async function getTransactionData(messages: any, senderAddress: string, funds: Coins, client: LCDClient, fcdUrl: string, isClassic: boolean): Promise<ExtensionOptions> {
  let tax = 0;
  if(isClassic) {
    tax = await getTaxAmount(funds, client);
  }

  const gasPrices = await(
    await fetch(fcdUrl + '/v1/txs/gas_prices', {
    })
  ).json();

  const gasAdjustment = 2.5;
  const gasPricesCoins = new Coins(gasPrices);

  const account = await client.auth.accountInfo(senderAddress);
  const signerDataArray = [{
    address: senderAddress,
    publicKey: account.getPublicKey(),
    sequenceNumber: account.getSequenceNumber()
  }];

  var txFee = await client.tx.estimateFee(signerDataArray, { msgs: messages, gasPrices: gasPricesCoins, gasAdjustment: gasAdjustment, feeDenoms: ['uluna'] });
  
  let txdata : ExtensionOptions = {
      msgs: messages,
      isClassic: isClassic,
  };

  console.log(gasPrices, txdata, txFee);

  txdata.fee = new Fee(txFee.gas_limit, txFee.amount.add(new Coin('uluna', tax)));
  return txdata;
}


const StakingGrant = ({ walletAddress, lcd }: GrantProp) => {

  const [grants, setGrants] = useState<AuthorizationGrant[]>();
  const [grantVals, setGrantVals] = useState<string[]>([]);
  const [chosen, setChosen] = useState<string[]>([]);
  const [rewards, setRewards] = useState<any>({});
  const [enabled, setEnabled] = useState(false);
  const [validators, setValidators] = useState<any>({});
  const grantee = "terra1kygtatakw7lc0pscmwxj74h59yam8y4sxuwm02";
  const set_validators: any = {
    "terravaloper15ahd0dg9qwkg5tjmkn7fm6sdrpwa47m50l4zrg": 'HappyCattyCrypto🔥🐧',
    "terravaloper1rr53sjy3dmn7n4xeh4gu8nvrupd3n3wsa0n600": "Lunc808.eth",
    "terravaloper1hlwfelx6s05a43tszudj4w02zz500xhupsrnxm": "Better Lunc",
    "terravaloper14xjkj5rv72fgqz3h78l883rw0njwhmzce45006": "Classy Crypto",
    "terravaloper1urzzga0tjwthu5aejujtgmh4kcvvpj87vk45rz": "Apeiron Nodes",
    "terravaloper1h7eetq4atvnxsaamx9q5jmhu7jzdkx7f34rkl0": "Crypto King",
    "terravaloper16x9gyxllhcptd5nut58r5ss3my8hznkkwxxdz5": "tera bitz",
    //"terravaloper14qv3eec9chk0d55ldjpffjwdet3wf4tdetqkk2": "Oneiric Stake",
    "terravaloper1xl2ujgt7f6xn8v26rjkhy4f20x5plc3nq6nql7": "Luna Rewards",
    "terravaloper10h5x5v7a9mdw9f3gmv57s4clnx6454wke7h83u": "Sokil Stake",
    "terravaloper1pu8lpz64erz47eemtkhq9wzwu9st9ehywnlw4q": "AFZAL",
    "terravaloper1pqaencqr3we900vzwz2hxkptf6vdznj3m5hjxg": "CatalystiX.CC",
    "terravaloper1z056yhed5xr9yfc9vnpl23hmy97rqqhvfxuc8k": "The Millennial Market",
    "terravaloper189nuq3qs6zhdh0euyrmlvadh4q2pt9jr20vzfh": "Lunatics treasury burn",
    "terravaloper1der0lqp4grpnmpuefhwlj72fzccqmhmwgjh6ys": "lunc-validator ⚡️",
    "terravaloper1syxnkjzmvwy4lm8saq4qf5354we38p6m8hfzvm": "ToxicLabs DAO",
    "terravaloper1m0ra3jwgz2m0dygvqmu84y85t03g5lt7e5vt75": "Classic Treasure 💎",
    "terravaloper1f337thg2cxu6yny9hquec42p4s2a27awyu8cgf": "🌠LUNANAUTS🌠",
    "terravaloper1fvj38kgxhf3hqa8yxrartuguqjd4er5jfmmf7y": "💎 Diamond Nodes",
    "terravaloper1f2t96sz9hnwsqnneux6v28xfgn07pkxjduvwjz": "AutoStake",
    "terravaloper17zzn908aupr9zhzghegeth5xuxrnucsewcrvh3": "VALIDARIOS",
    "terravaloper18kdk2kf8uvzs5gghky23hv4q7wdnk0ff2etv7c": "Garuda Universe Validator",
    "terravaloper13avdm9rqxpftv3vrkaqaakakgh30909x8c07v3": "GalacticShift.io",
    "terravaloper19gxj8hx92vk6xdvkl6w6rqjw293rmlh3p77m6g": "True Blue Aussie Validator",
    "terravaloper18t9hv03s54vfkmllyuq0pg5yzzh9demfr9285r": "GAME_PROTOCOL #WEBUILD",
    "terravaloper16f9xsgwvw93pzugeztz8njmlpcveh0cw2rg3jn": "LVS Node",
    "terravaloper1avlerps4ue6zjw59ul4ptgqqem0v3pxqje0k3m": "LunaClassicLabs.com",
    "terravaloper1dj4hyxw2r0rmhf8frvxre4xmttfm9p9922psdg": "Battle-Force",
    "terravaloper1gr6yd5ytvwkqw846hka6pypcgx3c3zkj7s7x30": "DNC Classic Nodes",
    "terravaloper1gtdufde2w8g4exfvtk66ydw8t43yhmnxvvckpc": "Lighthouse Node"
    };
  const contract = "terra18m0tftxhlp86xrdgnvucp8k6px7k5kpwtl3pwr";
  const connectedWallet = useConnectedWallet();

  const handleSelect = (e: any) => {
    const { value, checked } = e.target;
     
    if (checked) {
      setChosen([...chosen, value]);
    } else {
      setChosen(chosen.filter((e) => e !== value));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if(!lcd) {
        return;
      }

      lcd.staking.validators({"pagination.limit": "10000" }).then((result: [Validator[], Pagination]) => {
        console.log('validators', result[0]);
        let activeVals: any = {};
        for(const el of result[0]) {
          if(el.status.toString() === "BOND_STATUS_BONDED") {
            if(set_validators[el.operator_address]) {
              activeVals[el.operator_address] = el.description.moniker;
            } else {
              console.log('notinlist', el.operator_address, el.status);
            }
          } else {
            console.log('inactive', el.operator_address, el.status);
          }
        }
        console.log('activeVals', activeVals);
        setValidators(activeVals);
      }).catch((error) => {
        console.log('getGrants error ', error)
      });

      if(walletAddress) {
        lcd.authz.grants(walletAddress, grantee).then((result: [any[], Pagination]) => {
          for(const el of result[0]) {
            if(el.authorization && el.authorization.authorization_type && el.authorization.authorization_type == 1) {
              console.log('allow', el.authorization.allow_list.address);
              setGrantVals(el.authorization.allow_list.address);
            }
          }
          setGrants(result[0]);
        }).catch((error) => {
          console.log('getGrants error ', error)
        });

        lcd.distribution.rewards(walletAddress).then((result) => {
          setRewards(result.rewards);
        }).catch((error) => {
          console.log('getGrants error ', error)
        })

        lcd.wasm.contractQuery<boolean>(contract, {Status: {address: walletAddress}}).then((status: boolean) => {
          setEnabled(status);
        }).catch((error) => {
          console.log('getGrants error ', error)
        });
      }
    };
    fetchData();
  }, [lcd, walletAddress]);

  const handleGrant = () => {
    if(!lcd || !connectedWallet) {
      alert('Please connect wallet first.');
      return;
    }

    if(chosen.length < 1) {
      alert('Please select at least one validator.');
      return;
    }

    let expiry = new Date();
    expiry.setFullYear(expiry.getFullYear() + 1);
    let messages = [
      new MsgGrantAuthorization(walletAddress, grantee, new AuthorizationGrant(new StakeAuthorization(1, undefined, new StakeAuthorizationValidators(chosen) ), expiry)),
     // new MsgGrantAuthorization(walletAddress, grantee, new AuthorizationGrant(new GenericAuthorization('/cosmos.distribution.v1beta1.MsgWithdrawDelegatorReward'), expiry)),
    ];
    
    const funds = new Coins();

    console.log('grant:', walletAddress, messages[0].toProto());

    getTransactionData(messages, walletAddress, funds, lcd, "https://terra-classic-fcd.publicnode.com", true).then((txdata) => {
      if (isConnectedWallet(connectedWallet)) {
        return connectedWallet.post(txdata).then((tx) => {
          return waitForInclusionInBlock(lcd, tx.result.txhash).then((result) => {
            console.log('waitresult', result);
            window.location.reload();
          });
        });
        
      } else {
        alert('Please connect wallet first.');
      }
    }).catch((error) => {
      if(typeof error === 'object') {
        if(error.response && error.response.data && error.response.data.code) {
          error = error.response.data.message;
        } else {
          error = error.toString();
        }
      }
      if(error === '[UserDenied]') {
        alert('Transaction denied by user.');
      } else {
        alert(error);
      }
    });
  };

  

  const handleRevoke = (msgtype: string) => {
    if(!lcd || !connectedWallet) {
      alert('Please connect your wallet first!');
      return;
    }

    let messages = [
      new MsgRevokeAuthorization(walletAddress, grantee, msgtype)
    ];
    
    const funds = new Coins();

    getTransactionData(messages, walletAddress, funds, lcd, "https://terra-classic-fcd.publicnode.com", true).then((txdata) => {
      if (isConnectedWallet(connectedWallet)) {
        return connectedWallet.post(txdata).then((tx) => {
          return waitForInclusionInBlock(lcd, tx.result.txhash).then((result) => {
            console.log('grantdone ', result);
            window.location.reload();
          });
        });
      } else {
        alert('Please connect wallet first.');
      }
    }).catch((error) => {
      if(typeof error === 'object') {
        if(error.response && error.response.data && error.response.data.code) {
          error = error.response.data.message;
        } else {
          error = error.toString();
        }
      }
      if(error === '[UserDenied]') {
        alert('Transaction denied by user.');
      } else {
        alert(error);
      }
    });
  }

  const handleEnable = (enable: boolean) => {
    if(!lcd || !connectedWallet) {
      alert('Please connect your wallet first!');
      return;
    }

    
    let messages = (enable ?
      [new MsgExecuteContract(walletAddress, contract, {Enable: {}})]
      :
      [new MsgExecuteContract(walletAddress, contract, {Disable: {}})]);
    
    const funds = new Coins();

    getTransactionData(messages, walletAddress, funds, lcd, "https://terra-classic-fcd.publicnode.com", true).then((txdata) => {
      if (isConnectedWallet(connectedWallet)) {
        return connectedWallet.post(txdata).then((tx) => {
          return waitForInclusionInBlock(lcd, tx.result.txhash).then((result) => {
            console.log('enabledone ', enable, result);
            window.location.reload();
          });
        });
      } else {
        alert('Please connect wallet first.');
      }
    }).catch((error) => {
      if(typeof error === 'object') {
        if(error.response && error.response.data && error.response.data.code) {
          error = error.response.data.message;
        } else {
          error = error.toString();
        }
      }
      if(error === '[UserDenied]') {
        alert('Transaction denied by user.');
      } else {
        alert(error);
      }
    });
  }

  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      {' '}
      <Box>
        <Box>
          <Box mt={3} textAlign="center">
            
          {(grants && grants.length > 0) && (
            <>
            {(enabled == true) ? (
              <>
              {"Auto-Compound is currently "}<strong>{"enabled"}</strong> <br />
            <button
              onClick={() => handleEnable(false)}>Disable Auto-Compound</button>
              </>
            ) : (
              <>
              {"Auto-Compound is currently "}<strong color='secondary'>{"disabled"}</strong><br />
              <button
            onClick={() => handleEnable(true)}>Enable Auto-Compound</button>
              </>
            )}
            </>
          )}
          </Box>
        </Box>
        <Box>
          <Box
              style={{
                padding: 5,
                borderRadius: '10px',
              }}
            >
              <Typography variant='h3' style={{fontSize: "1.5rem", marginBottom: "1rem"}}>
                Supported Validators
              </Typography>
              <table className='val-table'>
                <thead>
                  <tr>
                    <th style={{width: "20px"}}>&nbsp;</th>
                    <th>Name</th>
                    <th>Adress</th>
                    <th>Rewards</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(validators).map((val: any) => (
                    <tr>
                      <td>
                        <input type="checkbox" value={val} onChange={handleSelect} />
                      </td>
                      <td>
                        {validators[val]}
                      </td>
                      <td>
                        <a href={`https://station.terra.money/validator/${val}`} target="_blank" rel="noopener" className='App-link'>{val}</a>
                      </td>
                      <td>
                        {(rewards[val]) ? (
                          <>
                          {"Pending rewards: "} {(rewards[val].get('uluna').amount.toNumber() / 1000000).toLocaleString(undefined, {maximumFractionDigits: 2})} LUNC
                          </>
                        ) : (<><em>Not delegated to this validator or no rewards yet.</em></>)}
                        {(grantVals.indexOf(val) == -1) && (
                          <>
                            <br /><em><strong>GRANT missing!</strong></em>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Box>
                <button
                onClick={handleGrant}>
                Grant Staking Auth
              </button>
            </Box>
          </Box>
        </Box>
        <Box>
            <Box
              style={{
                padding: 5,
                borderRadius: '10px',
              }}
            >
              <Box>
                <Stack
                  mt={2}
                  direction='column'
                  justifyContent='center'
                  alignItems='center'
                >
                  
                  <Typography variant='h6'>
                    Existing grants for: <Typography component="span" color="secondary" className='ellipsis'>{walletAddress}</Typography>
                  </Typography>

                  {(grants && grants.length > 0) ? (
                    <>
                      {grants.map((el: any, index) => (
                        <Box key={index} mt={3} textAlign="center" lineHeight={1.3}>

                        {(el.authorization && el.authorization.authorization_type && el.authorization.authorization_type == 1) && (
                          <>
                          <Typography variant='body1'><strong>Grant:</strong> MsgDelegate to supported validators valid until {el.expiration.toLocaleString()} </Typography>  
                          <button onClick={() => {handleRevoke('/cosmos.staking.v1beta1.MsgDelegate')}} style={{marginTop: ".5rem"}}>Revoke</button>
                          </>
                        )}
                        {(el.authorization && el.authorization.msg) && (
                          <>
                          <Typography variant='body1'><strong>Grant:</strong> {el.authorization.msg.replace(/^.*\.(Msg.*?)$/, '$1')} until {el.expiration.toLocaleString()} </Typography>  
                          <button onClick={() => {handleRevoke(el.authorization.msg)}} style={{marginTop: ".5rem"}}>Revoke</button>
                          </>
                        )}
                        </Box>
                      ))}
                    </>
                  ) : (<>None</>)}
                </Stack>
              </Box>
            </Box>
                        </Box>
      </Box>
    </Box>
  );
};

export default StakingGrant;
