import React, { useEffect, useState } from 'react';
import logo from './logo.jpg';
import './App.css';
import { createLCDClient, useConnectedWallet, useWallet, WalletStatus } from '@terra-money/wallet-provider';
import { ConnectWallet } from './ConnectWallet';
import StakingGrant from './StakingGrant/StakingGrant';
import { LCDClient } from '@terra-money/terra.js';

function App() {

  const {
    status,
    network,
    wallets,
    availableConnectTypes,
    availableConnections,
    connect,
    disconnect,
  } = useWallet();
  const lcdClients = {};
  const connectedWallet = useConnectedWallet();
  const [walletAddress, setWalletAddress] = useState("");
  const [lcd, setLCD] = useState<LCDClient|undefined>();

  useEffect(() => {
    const lcdclient = createLCDClient({
      lcdClientConfig: {isClassic: true}, network: {
        name: 'Classic',
        lcd: 'https://terra-classic-lcd.publicnode.com',
        chainID: 'columbus-5',
        walletconnectID: 2,
      }
    });
    setLCD(lcdclient);
  },[]);

  useEffect(() => {
    setWalletAddress(status === WalletStatus.WALLET_CONNECTED ? wallets[0].terraAddress : '');
  }, [connectedWallet])

  return (
    <div className="App">
      <header className="App-header">
        <h1>LUNC Auto-Compounding</h1>
        <ConnectWallet />
  </header>
      <div className="AppContent">
        <p>
          If you want to re-stake your rewards with our supported nodes, please connect your wallet (currently only WalletConnect via Terra Station mobile app supported). Then you can grant us the "Delegate" privileges via AuthZ. This is secure and can be revoked at any time. We are not able to send coins, undelegate or do other things than withdrawing rewards and re-delegating them. All rewards will only be re-delegated to <strong>the same node</strong> that they came from. Rewards are compounded daily at around 1am UTC, but only if they reached 1,000 LUNC or above. Only LUNC is compounded.
        </p>
  <StakingGrant walletAddress={walletAddress} lcd={lcd} />
      </div>
    </div>
  );
}

export default App;
